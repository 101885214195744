.profile {
  &__title {
    font-size: 3rem;
    margin-bottom: 1em;
  }
}

.btn-follow {
  border: 0;
  padding: 0;
  background: none;
}
