.card-container {
  width: 100%;

  & .blog-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & li:nth-of-type(2n+2) {
      margin-right: 0;
    } 

    & .blogCard {
      position: relative;
      margin: 20px 20px 0 0;
      width: calc((100% - 20px) / 2);
      height: 200px;
      border-radius: 10px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      &__title {
        max-width: 100%;
        z-index: 2;
        word-wrap: break-word;
      }

      &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 10px;
        color: var(--nav-text);
        text-align: center;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.6);
          transition: background 0.2s;
        }

        &:hover::before {
          background: rgba(40, 40, 40, 0.6);
          transition: background 0.2s;
        }

        &:active::before {
          background: rgba(40, 40, 40, 0.6);
          transition: background 0.2s;
        }

        &:focus::before {
          background: rgba(40, 40, 40, 0.6);
          transition: background 0.2s;
        }
      }
    }
  }
}

@media (min-width: $vpminw-tablet) {
  .card-container .blog-cards {

    & li:nth-of-type(2n+2) {
      margin-right: 20px;
    }

    & li:nth-of-type(3n+3) {
      margin-right: 0;
    }

    & .blogCard {
      width: calc((100% - 40px) / 3);
    }
  }

}
