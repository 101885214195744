/*** Initialize CSS ***/
/** Normalize **/
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/** Border-Box **/
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/** Clearfix **/
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0; }

/** Vincent Custom Style Reset **/
/* Headings */
h1, h2, h3, h4, h5, h6 {
  margin: 0; }

/* Unordered Lists */
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Links */
a {
  color: black;
  text-decoration: none; }

/*** /Initialize CSS ***/
/* Color Variables */
:root {
  --pg-bg: whitesmoke;
  --pg-title: var(--pg-bg);
  --hdrftr-bg: #000;
  --nav-text: var(--pg-bg);
  --body-text: #000;
  --link-hover: steelblue;
  --btn-hover: var(--link-hover); }

html {
  font-size: 62.5%; }

body {
  font-size: 1.4rem;
  font-family: "Nunito", sans-serif;
  background: var(--pg-bg); }

main {
  flex-grow: 1;
  width: 100%;
  min-width: 320px;
  min-height: 100%; }

p {
  margin: 0; }

.btn {
  border: 1px solid black;
  background: none;
  padding: 10px 20px;
  transition: border 0.2s, color 0.2s; }
  .btn:hover {
    border: 1px solid var(--btn-hover);
    color: var(--btn-hover);
    transition: border 0.2s, color 0.2s; }

.page {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 1920px;
  min-height: 100vh; }

.wrapper {
  padding: 20px 5%; }

.swal-button {
  text-transform: capitalize; }

@media (min-width: 600px) {
  body {
    font-size: 1.6rem; } }

@media (min-width: 900px) {
  body {
    font-size: 1.8rem; }
  .wrapper {
    padding: 25px 10%; } }

@media (min-width: 1920px) {
  .page {
    margin: 0 auto; }
  .wrapper {
    max-width: 1920px;
    margin: 0 auto; } }

.welcomeBG {
  position: relative;
  background: url(./assets/home2.jpg) center center/cover no-repeat; }

.welcome--loggedout {
  display: flex;
  flex-direction: column; }
  .welcome--loggedout p {
    margin-top: 20px; }

.btn-showType {
  margin-left: 10px;
  border: 0;
  padding: 0;
  background: none; }
  .btn-showType:hover {
    color: var(--link-hover); }

.btn-showMore {
  border: 0;
  background: none;
  margin-top: 1em; }

.banner {
  height: 30vh;
  min-height: 200px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.copyright {
  transition: color 0.2s; }
  .copyright:hover {
    color: var(--link-hover);
    transition: color 0.2s; }

.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.top-menu {
  display: flex;
  flex-direction: row; }
  .top-menu li:first-child {
    margin: 0; }

.menu-item {
  display: flex;
  position: relative;
  margin: 0 20px; }
  .menu-item .menu-link {
    display: flex;
    align-items: center;
    outline: 0;
    height: calc(1em + 20px);
    text-transform: capitalize; }
    .menu-item .menu-link::after {
      content: "";
      position: absolute;
      left: -10%;
      bottom: -10px;
      width: 120%;
      height: 3px;
      transition: background 0.2s; }
    .menu-item .menu-link:hover::after {
      background: var(--link-hover);
      transition: background 0.2s; }
    .menu-item .menu-link:active::after {
      background: var(--link-hover);
      transition: background 0.2s; }
    .menu-item .menu-link:focus::after {
      background: var(--link-hover);
      transition: background 0.2s; }
  .menu-item .userPhoto {
    margin-right: 10px;
    height: calc(1em + 20px);
    border-radius: 50%; }
  .menu-item .btn-auth {
    display: flex;
    align-items: center;
    height: calc(1em + 20px);
    padding: 0;
    border: none;
    outline: 0;
    background: none; }
    .menu-item .btn-auth::after {
      content: "";
      position: absolute;
      left: -10%;
      bottom: -10px;
      width: 120%;
      height: 3px;
      transition: background 0.2s; }
    .menu-item .btn-auth:hover::after {
      background: var(--link-hover);
      transition: background 0.2s; }
    .menu-item .btn-auth:active::after {
      background: var(--link-hover);
      transition: background 0.2s; }
    .menu-item .btn-auth:focus::after {
      background: var(--link-hover);
      transition: background 0.2s; }

@media (min-width: 900px) {
  .navigation {
    padding: 15px 10%; } }

.profile__title {
  font-size: 3rem;
  margin-bottom: 1em; }

.btn-follow {
  border: 0;
  padding: 0;
  background: none; }

.card-container {
  width: 100%; }
  .card-container .blog-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .card-container .blog-cards li:nth-of-type(2n+2) {
      margin-right: 0; }
    .card-container .blog-cards .blogCard {
      position: relative;
      margin: 20px 20px 0 0;
      width: calc((100% - 20px) / 2);
      height: 200px;
      border-radius: 10px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat; }
      .card-container .blog-cards .blogCard__title {
        max-width: 100%;
        z-index: 2;
        word-wrap: break-word; }
      .card-container .blog-cards .blogCard__link {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 10px;
        color: var(--nav-text);
        text-align: center; }
        .card-container .blog-cards .blogCard__link::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.6);
          transition: background 0.2s; }
        .card-container .blog-cards .blogCard__link:hover::before {
          background: rgba(40, 40, 40, 0.6);
          transition: background 0.2s; }
        .card-container .blog-cards .blogCard__link:active::before {
          background: rgba(40, 40, 40, 0.6);
          transition: background 0.2s; }
        .card-container .blog-cards .blogCard__link:focus::before {
          background: rgba(40, 40, 40, 0.6);
          transition: background 0.2s; }

@media (min-width: 600px) {
  .card-container .blog-cards li:nth-of-type(2n+2) {
    margin-right: 20px; }
  .card-container .blog-cards li:nth-of-type(3n+3) {
    margin-right: 0; }
  .card-container .blog-cards .blogCard {
    width: calc((100% - 40px) / 3); } }

.post__title {
  max-width: 100%;
  font-size: 5rem;
  word-wrap: break-word; }

.post__details {
  margin: 1em 0;
  font-weight: normal;
  font-style: italic; }
  .post__details__postedBy {
    font-weight: bold; }

.list-header {
  display: flex;
  margin-top: 15px;
  width: 100%;
  padding: 10px; }
  .list-header__item {
    width: 27.5%; }
    .list-header__item--snippet {
      display: none; }
    .list-header__item--button {
      display: flex;
      justify-content: center;
      width: 15%; }

.blog-list {
  display: flex;
  flex-direction: column; }
  .blog-list__item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid gray; }
    .blog-list__item:first-child {
      margin-top: 0; }
    .blog-list__item__info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 27.5%;
      padding-right: 15px; }
      .blog-list__item__info--button {
        display: flex;
        justify-content: center;
        width: 15%;
        padding-right: 0; }
        .blog-list__item__info--button a {
          border: none; }
          .blog-list__item__info--button a:hover {
            border: none; }
          .blog-list__item__info--button a > svg {
            position: relative;
            z-index: -1; }
        .blog-list__item__info--button button {
          border: none; }
          .blog-list__item__info--button button:hover {
            border: none; }
          .blog-list__item__info--button button > svg {
            position: relative;
            z-index: -1; }
      .blog-list__item__info--snippet {
        display: none; }
    .blog-list__item__link {
      padding: 0 10px;
      transition: color 0.2s; }
      .blog-list__item__link:hover {
        color: var(--link-hover);
        transition: color 0.2s; }

@media (min-width: 600px) {
  .list-header__item {
    width: 23.33333%; }
    .list-header__item--snippet {
      display: flex; }
    .list-header__item--button {
      width: 10%; }
  .blog-list__item__info {
    width: 23.33333%; }
    .blog-list__item__info--snippet {
      display: flex;
      align-items: center;
      font-size: 0.8em;
      color: gray; }
    .blog-list__item__info--button {
      width: 10%; } }

.editor {
  display: flex;
  justify-content: center;
  width: 100%; }
  .editor form {
    width: 100%; }

.draft-title {
  margin-bottom: 20px; }
  .draft-title--label {
    margin-right: 20px; }

.draft-editor {
  width: 100%;
  height: 80vh;
  min-height: 200px;
  resize: vertical; }
  .draft-editor:active, .draft-editor:focus {
    border: 1px solid steelblue; }
  .draft-editor::placeholder {
    opacity: 0.5; }

.btn-save {
  margin-top: 20px; }
