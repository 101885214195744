.post {

  &__title {
    max-width: 100%;
    font-size: 5rem;
    word-wrap: break-word;
  }

  &__details {
    margin: 1em 0;
    font-weight: normal;
    font-style: italic;

    &__postedBy {
      font-weight: bold;
    }
  }

}