.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.top-menu {
  display: flex;
  flex-direction: row;

  & li:first-child {
    margin: 0;
  }
}


$menuitem-hovertransition: background 0.2s;

.menu-item {
  display: flex;
  position: relative;
  margin: 0 20px;
  
  & .menu-link {
    display: flex;
    align-items: center;
    outline: 0;
    height: calc(1em + 20px);
    text-transform: capitalize;

    &::after {
      content: "";
      position: absolute;
      left: -10%;
      bottom: -10px;
      width: 120%;
      height: 3px;
      transition: $menuitem-hovertransition;
    }

    &:hover::after {
      background: var(--link-hover);
      transition: $menuitem-hovertransition;
    }

    &:active::after {
      background: var(--link-hover);
      transition: $menuitem-hovertransition;
    }

    &:focus::after { 
      background: var(--link-hover);
      transition: $menuitem-hovertransition;
    }
  }

  & .userPhoto {
    margin-right: 10px;
    height: calc(1em + 20px);
    border-radius: 50%;
  }

  & .btn-auth {
    display: flex;
    align-items: center;
    height: calc(1em + 20px);
    padding: 0;
    border: none;
    outline: 0;
    background: none;

    &::after {
      content: "";
      position: absolute;
      left: -10%;
      bottom: -10px;
      width: 120%;
      height: 3px;
      transition: $menuitem-hovertransition;
    }
    
    &:hover::after {
      background: var(--link-hover);
      transition: $menuitem-hovertransition;
    }

    &:active::after {
      background: var(--link-hover);
      transition: $menuitem-hovertransition;
    }

    &:focus::after { 
      background: var(--link-hover);
      transition: $menuitem-hovertransition;
    }
  }
}


@media (min-width: $vpminw-mdrnmbl) {

}

@media (min-width: $vpminw-tablet) {

}

@media (min-width: $vpminw-desktop) {
  .navigation {
    padding: 15px $wrprlr-desktop;
  }
}

@media (min-width: $vpminw-lrgscrn) {
  
}
