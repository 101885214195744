.welcomeBG {
  position: relative;
  background: url(./assets/home2.jpg) center center / cover no-repeat;
}

.welcome--loggedout {
  display: flex;
  flex-direction: column;
  // justify-content: center;

  & p {
    margin-top: 20px;
  }
}

.btn-showType {
  margin-left: 10px;
  border: 0;
  padding: 0;
  background: none;

  &:hover {
    color: var(--link-hover);
  }
}

.btn-showMore {
  border: 0;
  background: none;
  margin-top: 1em;
}
